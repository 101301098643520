<template>
  <div>
    <!-- View project modal template -->
    <b-modal ref="view-project"
             @hide="hideProjectModal"
             size="xl"
             modal-class="bottom-left task-modal"
    >
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideProjectModal" class="btn btn-secondary cursor-pointer float-right">
            <font-awesome-icon icon="times"/>
          </span>

          <div class="d-block text-center">
            <h4 qid="project-view-title">
              {{ $t('system.view_project') }}
              {{ $t('system.for') }}
              <font-awesome-icon icon="calendar-alt" size="lg" class="ml-1"/>
              {{ project.title }}
            </h4>
          </div>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>

      <b-form qid="create-p-form" v-if="project && project.id">
        <div class="form-container mt-0 mb-2">
          <h5>
            {{ $t('projects.create.task_assignees') }}
            <b-button class="float-right"
                      variant="light"
                      v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && !((project.type != 'control_assessment' && project.type != 'risk_assessment' && project.type != 'bia_assessment' && ((project.status == null || project.status == 'pending' || project.status == 'in progress') && (project.task_count == 0 || project.task_count == undefined))) || ((project.type == 'control_assessment' || project.type == 'risk_assessment' || project.type == 'bia_assessment') && (project.status == null || project.status == 'pending' || project.status == 'in progress') && (project.task_count == 0 || project.task_count == undefined) && ((!project.system_impact_assessment) || (project.system_impact_assessment && (project.system_risk_count == 0 || project.system_risk_count == undefined))) && ((project.include_mapping && measureTask && measureTask.status == 'completed') || !project.include_mapping)))"
                      @click="sendReminders(project)"
                      qid="delete-s-cancel-button"
            >
              {{ $t('tasks.send_reminders') }}
            </b-button>

            <span class="mr-3 btn btn-success cursor-pointer float-right"
                  @click="showConfirmCompleteModal(project)"
                  qid="complete-project"
                  v-if="project.control_type == 'one time' && project.type != 'control_assessment' && project.type != 'risk_assessment' && project.type != 'bia_assessment' && (project.status == null || project.status == 'pending' || project.status == 'in progress') && project.progress == 100"
            >
              {{ $t('projects.button.complete_project') }}
            </span>

             <span class="mr-3 btn btn-light cursor-pointer float-right"
                   @click="showConfirmCloseModal(project)"
                   qid="close-project"
                   v-if="project.control_type == 'one time' && project.type != 'control_assessment' && project.type != 'risk_assessment' && project.type != 'bia_assessment' && (project.status == null || project.status == 'pending' || project.status == 'in progress') && project.progress == 100"
             >
               {{ $t('projects.button.close_project') }}
             </span>

            <span class="mr-3 btn btn-success cursor-pointer float-right"
                  @click="showConfirmCompleteModal(project)"
                  qid="complete-project"
                  v-if="project.control_type == 'one time' && (project.type == 'control_assessment' || project.type == 'risk_assessment' || project.type == 'bia_assessment') && (project.status == null || project.status == 'pending' || project.status == 'in progress') && project.progress == 100 && ( (!project.system_impact_assessment) || (project.system_impact_assessment && (project.system_risk_count == 0 || project.system_risk_count == undefined))) && ((project.include_mapping && measureTask && measureTask.status == 'completed') || !project.include_mapping)"
            >
              {{ $t('projects.button.complete_project') }}
            </span>

              <span class="mr-3 btn btn-light cursor-pointer float-right"
                    @click="showConfirmCloseModal(project)"
                    qid="close-project"
                    v-if="project.control_type == 'one time' && (project.type == 'control_assessment' || project.type == 'risk_assessment' || project.type == 'bia_assessment') && (project.status == null || project.status == 'pending' || project.status == 'in progress') && project.progress == 100 && ( (!project.system_impact_assessment) || (project.system_impact_assessment && (project.system_risk_count == 0 || project.system_risk_count == undefined))) && ((project.include_mapping && measureTask && measureTask.status == 'completed') || !project.include_mapping)"
              >
                {{ $t('projects.button.close_project') }}
              </span>

            <span class="mr-3 btn btn-light cursor-pointer float-right"
                  @click="showConfirmReopenModal(project)"
                  qid="close-project"
                  v-if="project.control_type == 'one time' && (project.status == 'closed' || project.status == 'completed')"
            >
              {{ $t('projects.button.reopen_project') }}
           </span>
          </h5>
          <br>

          <table class="table table-responsive-md">
            <thead>
              <tr>
                <th>
                  {{ $t('projects.table.name') }}
                </th>
                <th class="text-center">
                  {{ $t('table.progress') }}
                </th>
                <th width="50%"></th>
                <th width="200"></th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(object, index) in uniqueUsers" :key="index">
                <td>
                  <span class="mr-3 user-tag">
                    <Avatar :object="object" size="xs"></Avatar>
                    <span class="ml-2">{{ object.name }}</span>
                  </span>
                </td>
                <td class="text-center" style="line-height: 22px;">
                  <div v-if="object.tasks">
                    <span>{{ (object.completed_tasks / object.tasks * 100).toFixed(0) }}%</span>
                  </div>
                  <div class="text-muted" v-if="object.tasks">
                    <span>{{ object.completed_tasks }} / {{ object.tasks }}</span>
                  </div>
                </td>
                <td style="line-height: 22px;">
                  <div v-if="object.tasks"
                       class="pt-3 project-progress"
                       :class="{'project-progress-zero': calculateProgress(object.completed_tasks, object.tasks) == 0}"
                       :title="getTitle(object.completed_tasks, object.tasks, project.date_progress)"
                  >
                    <b-progress
                        :value="calculateProgress(object.completed_tasks, object.tasks)"
                        :max="100"
                        variant="success"
                        class="sucess"
                        v-if="calculateProgress(object.completed_tasks, object.tasks) == 100"
                    ></b-progress>
                    <b-progress
                        :value="calculateProgress(object.completed_tasks, object.tasks)"
                        :max="100"
                        variant="info"
                        class="info"
                        v-if="calculateProgress(object.completed_tasks, object.tasks) >= project.date_progress && project.date_progress < 100 && calculateProgress(object.completed_tasks, object.tasks) < 100"
                    ></b-progress>
                    <b-progress
                        :value="calculateProgress(object.completed_tasks, object.tasks)"
                        :max="100"
                        variant="warning"
                        class="warning"
                        v-if="calculateProgress(object.completed_tasks, object.tasks) < project.date_progress && project.date_progress < 100 && calculateProgress(object.completed_tasks, object.tasks) < 100"
                    ></b-progress>
                    <b-progress
                        :value="calculateProgress(object.completed_tasks, object.tasks)"
                        :max="100"
                        variant="danger"
                        class="danger"
                        v-if="project.date_progress >= 100 && calculateProgress(object.completed_tasks, object.tasks) < 100"
                    ></b-progress>
                  </div>
                </td>
                <td>
                  <span v-if="object.tasks">
                    <b-button class="float-right"
                              variant="light"
                              v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && calculateProgress(object.completed_tasks, object.tasks) < 100"
                              @click="sendReminderToUser(object)"
                              qid="send-reminder-button"
                    >
                      {{ $t('tasks.send_reminder') }}
                    </b-button>
                  </span>
                </td>
              </tr>
            </tbody>

            <tbody v-if="uniqueUsers && !uniqueUsers.length && !loading">
              <tr qid="no-results-row" class="text-center">
                <td colspan="10">
                  <h5 class="mt-3">
                    {{ $t('system.no_results') }}
                  </h5>
                </td>
              </tr>
            </tbody>

            <tbody v-if="loading">
              <tr qid="loading-row" class="text-center">
                <td colspan="10">
                  <div class="mt-3 text-center">
                    <h1>
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </h1>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-form>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="task-modal-info" v-if="project && project.id">
            <div class="row">
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('projects.create.owners_label') }}</strong>
                </div>

                <span class="mr-1 text-normal"
                      v-for="(user, user_index) in getReducedUsers(project.users)"
                      :key="user_index"
                >
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                <span v-if="project.users.length > 3"
                      class="badge cursor-pointer"
                      @click="openUsersModal(project)"
                >
                  <font-awesome-icon icon="users"/>
                  + {{ project.users.length - 3 }}
                </span>
              </div>

              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('projects.projects_table.status') }}</strong>
                </div>

                <span v-if="project.status === 'completed'" class="pr-1">
                  <font-awesome-icon icon="check-circle" class="icon-success"/>
                </span>
                <span v-if="project.status === 'pending'" class="pr-1">
                  <font-awesome-icon icon="exclamation-triangle" class="risk-1"/>
                </span>
                <span v-if="project.status === 'in progress'" class="pr-1">
                  <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
                </span>
                <span v-if="project.status === 'closed'" class="pr-1">
                  <font-awesome-icon icon="exclamation-triangle" class="risk-12"/>
                </span>

                <span class="text-normal">
                  {{ $t('system.' + $options.filters.slugify(project.status)) }}
                </span>
              </div>

              <div class="col text-center pt-3 pb-3" v-if="project.data_collection_end_date">
                <div class="mb-3">
                  <strong>{{ $t('projects.schedule.data_due_date_label') }}</strong>
                </div>

                <span class="text-normal">
                  <span v-if="getDifference(project.data_collection_end_date) > 3">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-1"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(project.data_collection_end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(project.data_collection_end_date) <= 3 && getDifference(project.data_collection_end_date) >= 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(project.data_collection_end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(project.data_collection_end_date) < 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-12"/>
                    {{ Math.abs(getDifference(project.data_collection_end_date)) }}
                    {{ $t('system.days') }}
                    {{ $t('system.overdue') }}
                  </span>
                  <span v-if="!project.data_collection_end_date">
                    {{ $t('system.n_a') }}
                  </span>
                </span>
              </div>

              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('projects.schedule.due_date_label') }}</strong>
                </div>

                <span class="text-normal">
                  <span v-if="getDifference(project.end_date) > 3">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-1"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(project.end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(project.end_date) <= 3 && getDifference(project.end_date) >= 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(project.end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(project.end_date) < 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-12"/>
                    {{ Math.abs(getDifference(project.end_date)) }}
                    {{ $t('system.days') }}
                    {{ $t('system.overdue') }}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">
              </div>
              <div class="col-sm-6 text-right">
                <b-button class="ml-3 mt-2"
                          variant="secondary"
                          @click="hideProjectModal"
                          qid="create-p-cancel-button"
                >
                  {{ $t('processes.policies.view.close') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>


    <!-- Complete Project modal template -->
    <b-modal ref="complete-project"
             hide-footer
             @hide="hideConfirmCompleteModal"
             modal-class="task-modal"
    >
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideConfirmCompleteModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-p-title">
            {{ $t('projects.confirm.complete') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>

      <h5 class="text-center">
        {{ $t('projects.confirm.complete_description') }}
        <br>
        <span style="color:#00c29e">{{ itemToChangeStatus.title }}</span>
      </h5>

      <div class="proxy-footer">
        <div class="alert alert-menu-color color-secondary mb-0" role="alert">
          <div class="text-center">
            <b-button class=""
                      variant="secondary"
                      @click="hideConfirmCompleteModal"
                      qid="complete-p-cancel-button"
            >
              {{ $t('activities.delete.cancel') }}
            </b-button>
            <b-button @click="completeProject()"
                      class="ml-3"
                      variant="success"
                      qid="complete-p-submit-button"
                      v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], itemToChangeStatus)"
            >
              {{ $t('activities.delete.submit') }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>


    <!-- Close Project modal template -->
    <b-modal ref="close-project"
             hide-footer
             @hide="hideConfirmCloseModal"
             modal-class="task-modal"
    >
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideConfirmCloseModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-p-title">
            {{ $t('projects.confirm.close') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>

      <h5 class="text-center">
        {{ $t('projects.confirm.close_description') }}:
        <br>
        <span style="color:#00c29e">{{ itemToChangeStatus.title }}</span>
      </h5>

      <div class="proxy-footer">
        <div class="alert alert-menu-color color-secondary mb-0" role="alert">
          <div class="text-center">
            <b-button class=""
                      variant="secondary"
                      @click="hideConfirmCloseModal"
                      qid="close-p-cancel-button"
            >
              {{ $t('activities.delete.cancel') }}
            </b-button>
            <b-button @click="closeProject()"
                      class="ml-3"
                      variant="success"
                      qid="close-p-submit-button"
                      v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], itemToChangeStatus)"
            >
              {{ $t('activities.delete.submit') }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>


    <!-- Reopen Project modal template -->
    <b-modal ref="reopen-project"
             hide-footer
             @hide="hideConfirmReopenModal"
             modal-class="task-modal"
    >
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideConfirmReopenModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>

          <b-alert v-if="error.length"
                   show
                   variant="danger"
                   qid="create-p-error"
          >
            {{ error }}
          </b-alert>

          <h5 class="mb-1 text-center" qid="create-p-title">
            {{ $t('projects.confirm.reopen') }}
          </h5>
        </div>
      </template>

      <h5 class="text-center">
        {{ $t('projects.confirm.reopen_description') }}:
        <br>
        <span style="color:#00c29e">{{ itemToChangeStatus.title }}</span>
      </h5>

      <div class="proxy-footer">
        <div class="alert alert-menu-color color-secondary mb-0" role="alert">
          <div class="text-center">
            <b-button class=""
                      variant="secondary"
                      @click="hideConfirmReopenModal"
                      qid="delete-s-cancel-button"
            >
              {{ $t('activities.delete.cancel') }}
            </b-button>
            <b-button @click="reopenProject()"
                      class="ml-3"
                      variant="success"
                      qid="delete-s-submit-button"
                      v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], itemToChangeStatus)"
            >
              {{ $t('activities.delete.submit') }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';

  export default {
    name: 'ProjectViewModal',
    data: function() {
      return {
        error: '',
        project: {},
        itemToChangeStatus: {},
        statusChanged: false,
        tasks: [],
        loading: true,
        uniqueUsers: [],
        loadingItems: false,
        showFullProjectMessage: false
      }
    },
    props: {
      slug: {},
      showModal: {
        type: Boolean
      }
    },
    components: {
      Avatar
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when prop changes
      showModal: function (value) {
        if (value) {
          this.showProjectModal()
        }
      }
    },
    methods: {
      getDifference(endDate) {
        var moment = require('moment');
        let currentDate = moment()
        endDate = moment(endDate);
        let difference = endDate.diff(currentDate, 'days');
        return difference;
      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users) {
          if (users.length > 3) {
            reducedUsers = _.cloneDeep(users);
            reducedUsers = reducedUsers.slice(0, 3);
          } else {
            reducedUsers = users;
          }
        }
        return reducedUsers;
      },
      showConfirmCompleteModal(object) {
        this.itemToChangeStatus = object;
        this.$refs['complete-project'].show();
      },
      showConfirmCloseModal(object) {
        this.itemToChangeStatus = object;
        this.$refs['close-project'].show();
      },
      showConfirmReopenModal(object) {
        this.itemToChangeStatus = object;
        this.$refs['reopen-project'].show();
      },
      hideConfirmReopenModal() {
        this.itemToChangeStatus = {};
        this.$refs['reopen-project'].hide();
      },
      hideConfirmCompleteModal() {
        this.itemToChangeStatus = {};
        this.$refs['complete-project'].hide();
      },
      hideConfirmCloseModal() {
        this.itemToChangeStatus = {};
        this.$refs['close-project'].hide();
      },
      completeProject() {
        let self = this;
        piincHttp.put('projects/' + this.itemToChangeStatus.slug, {
          status: "completed"
        }).then(function(response) {
          self.project = response.data;
          self.$refs['complete-project'].hide();
          self.statusChanged = true;
          self.$toastr('success', self.$t('projects.update.success'));
        }, function(error) {
          if (error.status !== 200) {
            self.error = error.data.message;
          }
        })
      },
      closeProject() {
        let self = this;
        piincHttp.put('projects/' + this.itemToChangeStatus.slug, {
          status: "closed"
        }).then(function(response) {
          self.project = response.data;
          self.$refs['close-project'].hide();
          self.statusChanged = true;
          self.$toastr('success', self.$t('projects.update.success'));
        }, function(error) {
          if (error.status !== 200) {
            self.error = error.data.message;
          }
        })
      },
      reopenProject() {
        let self = this;
        piincHttp.put('projects/' + this.itemToChangeStatus.slug, {
          status: "in progress"
        }).then(function(response) {
          self.project = response.data;
          self.$refs['reopen-project'].hide();
          self.statusChanged = true;
          self.$toastr('success', self.$t('projects.update.success'));
        }, function(error) {
          if (error.status !== 200) {
            self.error = error.data.message;
          }
        })
      },
      showProjectModal() {
        let self = this;
        this.statusChanged = false;
        this.project = {};
        this.tasks = [];
        this.loadProject();
        this.loadTasks();
        this.$refs['view-project'].show();
      },
      hideProjectModal() {
        this.error = ''
        this.showArticleDescription = false;
        this.$emit('closeViewModal', this.statusChanged);
        this.$refs['view-project'].hide()
      },
      calculateProgress(current, total) {
        var progress = 0

        progress = parseInt((current / total * 100).toFixed(0))

        return progress
      },
      getTitle(current, total, dateProgress) {
        let progress = 0
        let message = ''

        progress = parseInt((current / total * 100).toFixed(0))

        if (dateProgress > 100 && progress < 100) {
          return 'Overdue'
        } else {
          if (progress == 100) {
            return 'Completed'
          }
          if (progress >= dateProgress && progress < 100) {
            return 'On schedule'
          }
          if (progress < dateProgress) {
            return 'Behind compared to project due date'
          }
        }

        return progress
      },
      loadProject() {
        let self = this;
        piincHttp.get('projects/' + this.slug, { params:
          {
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.project = response.data;
        }, function() {});
      },
      sendReminders(object) {
        let self = this;
        // Call API for reminders
        piincHttp.get('projects/' + this.slug + '/reminders').then(function(response) {
          self.$toastr('success', self.$t('projects.create.project_reminders_success'))
        }, function() {});
      },
      sendReminderToUser(object) {
        let self = this;
        // Call API for reminders
        piincHttp.get('projects/' + this.slug + '/reminders', { params:
          {
            user: object.id
          }
        }).then(function(response) {
          self.$toastr('success', self.$t('projects.create.project_reminders_success'))
        }, function() {});
      },
      loadTasks() {
        this.loading = true;
        let self = this;
        // Call API for open tasks
        piincHttp.get('projects/' + this.slug + '/tasks/assignees', { params:
          {
            client: this.getClient.slug,
            per_page: 100
          }
        }).then(function(response) {

          self.uniqueUsers = response.data;

          if (!self.uniqueUsers.length) {
            self.loading = false;
          }

          _.forEach(self.uniqueUsers, function(user, index) {
            self.loadTasksForUser(user, index)
          })
        }, function() {});
      },
      loadTasksForUser(user, index) {
        this.loading = true;
        let self = this;
        // Call API for open tasks
        piincHttp.get('projects/' + this.slug + '/tasks/count', { params:
          {
            client: this.getClient.slug,
            user: user.id,
          }
        }).then(function(response) {
          user.tasks = response.data.total;
          user.completed_tasks = response.data.completed
          user.pending_tasks = response.data.not_completed
          self.$set(self.uniqueUsers, index, user)
          self.loading = false;
        }, function() {});
      },
    },
    created () {
    }
  }
</script>
